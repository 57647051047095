<template>
  <v-container fluid>
    <Dashboard
      v-if="isLoaded && dashboardData && dashboardData.is_active"
      :company-id="companyId"
      :dashboard-id="dashboardId"
      :service-point-id="servicePointId"
      :is-filters-expanded="isFiltersExpanded"
      :dashboard-data="dashboardData"
      :key="dashboardId"
    />
    <v-alert
      v-else-if="isLoaded && (!dashboardData || !dashboardData.is_active)"
      border="bottom"
      colored-border
      type="info"
      elevation="2"
    >
      <span>Дашборд тимчасово відключено</span>
    </v-alert>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'DashboardSP',

  components: {
    Dashboard: () => import('@/components/common/Dashboard'),
  },

  props: {
    dashboardId: {
      required: true,
    },
  },

  data: () => ({
    isFiltersExpanded: false,
    isLoaded: false,
  }),

  computed: {
    ...mapState('dashboard', ['dashboardData']),

    companyId() {
      return this.$route.params.id
    },
    servicePointId() {
      return this.$route.params.servicePointId
    },
  },

  // watch: {
  //   '$route.hash'(newVal) {
  //     console.log(newVal)
  //   },
  // },

  mounted() {
    this.initialize()
  },

  beforeDestroy() {
    this.resetDashboardData()
  },

  methods: {
    ...mapActions('dashboard', ['loadDashboardData']),
    ...mapMutations('dashboard', ['SET_DASHBOARD_DATA']),

    async initialize() {
      const payload = {
        companyId: this.companyId,
        dashboardId: this.dashboardId,
      }
      await this.loadDashboardData(payload)
      this.isLoaded = true
    },
    resetDashboardData() {
      this.SET_DASHBOARD_DATA(null)
    },
  },
}
</script>
